<template>
  <div
    class="drivers page d-flex flex-column fill-height"
    :class="{ mobile }"
    v-resize="onResize"
  >
    <v-toolbar 
      fixed
      flat
      color="transparent"
      class="toolbar flex-grow-0"
    >
      <v-text-field
        v-model="input.search.query"
        :loading="input.search.loading"
        :disabled="input.search.disabled"
        :error-messages="input.search.messages"
        :prepend-inner-icon="icons.mdiMagnify"
        solo
        flat
        dense
        placeholder="Buscar"
        clearable
        hide-details
        :color="input.search.focus ? 'primary' : undefined"
        @blur="input.search.query==null ? input.search.focus=false : input.search.focus=true"
        @focus="input.search.focus=true"
        @keyup.esc="input.search.query=null;"
        @keyup.enter="searchDriver(input.search.query, input.search);"
        :style="{ 'width': breakpoint(null,'xs') ? input.search.focus ? '50vw' : '48px' : input.search.focus ? '25vw' : '140px' }"
        style="transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1) width;"
        class="flex-shrink-0"
      />
      <v-divider vertical class="mx-2" />
      <v-icon 
        v-if="breakpoint('xs',null)"
        color="grey"
        class="mx-2"
      >
        {{ icons.mdiFilterVariant }}
      </v-icon>
      <div class="filters d-flex align-start scrollable x">
        <v-combobox
          v-model="input.city.value"
          :items="input.city.items"
          :loading="input.city.loading"
          :disabled="input.city.disabled"
          :label="input.city.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          clearable
          chips
          small-chips
          multiple
          hide-details
          outlined
          dense
          color="primary"
          class="city-filter filter ma-2"
        />
        <v-autocomplete
          v-model="input.model.value"
          :items="input.model.items"
          :loading="input.model.loading"
          :disabled="input.model.disabled"
          :label="input.model.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          clearable
          multiple
          hide-details
          outlined
          dense
          color="primary"
          class="filter model-filter ma-2 ml-0"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip 
              v-if="index === 0"
              small
            >
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >
              (+{{ input.model.value.length - 1 }})
            </span>
          </template>
        </v-autocomplete>
        <v-select
          v-model="input.owner.value"
          :items="input.owner.items"
          :loading="input.owner.loading"
          :disabled="input.owner.disabled"
          :label="input.owner.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          clearable
          multiple
          hide-details
          outlined
          dense
          color="primary"
          class="owner-filter filter ma-2 ml-0"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip 
              v-if="index === 0"
              small
              label
            >
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >
              (+{{ input.owner.value.length - 1 }})
            </span>
          </template>
        </v-select>
        <v-select
          v-model="input.special_projects.value"
          :items="input.special_projects.items"
          :loading="input.special_projects.loading"
          :disabled="input.special_projects.disabled"
          :label="input.special_projects.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          multiple
          clearable
          hide-details
          outlined
          dense
          color="primary"
          class="special_projects-filter filter ma-2 ml-0"
        />
        <v-select
          v-model="input.gender.value"
          :items="input.gender.items"
          :loading="input.gender.loading"
          :disabled="input.gender.disabled"
          :label="input.gender.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          clearable
          multiple
          hide-details
          outlined
          dense
          color="primary"
          class="gender-filter filter ma-2 ml-0"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip 
              v-if="index === 0"
              small
              label
            >
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
            >
              (+{{ input.gender.value.length - 1 }})
            </span>
          </template>
        </v-select>
        <v-select
          v-model="input.rating.value"
          :items="input.rating.items"
          :loading="input.rating.loading"
          :disabled="input.rating.disabled"
          :label="input.rating.label"
          menu-props="bottom, offsetY"
          clearable
          multiple
          hide-details
          outlined
          dense
          color="primary"
          class="rating-filter filter ma-2 ml-0"
        >
          <template v-slot:item="{ item }">
            <v-icon
              v-for="rating in item.value"
              :key="'rating-filter-item-'+rating"
              :color="item.color"
              size="24"
            >
              {{ icons.mdiStar }}
            </v-icon>
          </template>
          <template v-slot:selection="{ item }">
            <v-icon
              :color="item.color"
              size="20"
            >
              {{ icons.mdiStar }}
            </v-icon>
          </template>
        </v-select>
        <v-combobox
          v-model="input.tags.value"
          :items="tags"
          :loading="input.tags.loading"
          :disabled="input.tags.disabled"
          :label="input.tags.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          clearable
          multiple
          hide-no-data
          hide-details
          chips
          small-chips
          outlined
          dense
          color="primary"
          class="tags-filter filter ma-2 ml-0"
        />
        <v-select
          v-model="input.app_version.value"
          :items="input.app_version.items"
          :loading="input.app_version.loading"
          :disabled="input.app_version.disabled"
          :label="input.app_version.label"
          :menu-props="{ bottom: true, offsetY: true, contentClass: 'dense-list' }"
          clearable
          multiple
          hide-details
          outlined
          dense
          color="primary"
          class="app_version-filter filter ma-2 ml-0"
        />
        <v-select
          v-model="input.limit.value"
          :items="input.limit.items"
          :loading="input.limit.loading"
          :disabled="input.limit.disabled"
          :label="input.limit.label"
          menu-props="auto, light"
          hide-details
          outlined
          dense
          color="primary"
          class="status-filter filter ma-2 ml-auto"
          style="max-width: 96px;"
        />
      </div>
      <v-divider vertical class="mx-0" />
      <v-btn
        color="grey"
        icon
        @click="loadList(null, true);"
      >
        <v-icon>{{ icons.mdiRefresh }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet
      max-width="100vw"
      color="transparent"
      class="driver-funnel d-flex flex-grow-1 mt-4 pl-16"
    >
      <v-card 
        tile
        elevation="0"
        class="step-selection fill-height mr-2 mb-2"
      >
        <v-navigation-drawer
          permanent
          floating
          :mini-variant.sync="input.lists.toggle"
          expand-on-hover
          :width="breakpoint(null,'xs') ? 'calc(100vw - 32px)' : '280px'"
          color="transparent"
        >
          <v-subheader>
            <v-icon class="mr-4">{{ icons.mdiViewColumnOutline }}</v-icon>
            Etapas
            <v-spacer />
            <v-btn
              icon
              @click="resetList"
            >
              <v-icon small>
                {{ icons.mdiCheckboxBlankOff }}
              </v-icon>
            </v-btn>
          </v-subheader>
          <!-- <v-divider /> -->
          <v-list class="transparent">
            <v-list-item 
              v-for="(l,s) in list"
              :key="'toggle-'+s"
              class="flex-shrink-0"
              @click="toggleList(s, !l.toggle)"
            >
              <v-list-item-action class="mr-4">
                <v-badge
                  :value="filterList(l.items).length>0"
                  dot
                  overlap
                >
                  <v-checkbox 
                    :input-value="l.toggle"
                    :loading="l.loading"
                    readonly
                    color="grey"
                  />
                </v-badge>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ l.title }}
                  <v-badge
                    :value="filterList(l.items).length>0"
                    :content="filterList(l.items).length"
                    class="ml-2"
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <div class="driver-funnel pl-2 pb-2 d-flex snap-x">
        <driver-list
          v-for="(l,s) in list"
          :key="'drivers-'+s"
          :status="s"
          :title="l.title"
          :toggle="l.toggle"
          :list="filterList(l.items)"
          :order="l.order"
          :loading="l.loading"
          :driver="selected"
          :max-height="listHeight"
          :loading-driver="driverView.loading"
          class="snap-child flex-shrink-0 mr-2"
          @toggle-messenger="toggleMessenger"
          @get-profile="selectDriver"
          @update="loadList"
        />
        <div style="padding-right: 8px; height: 100%;" />
      </div>
    </v-sheet>
    <driver 
      :toggle="toggleDriver"
      :data="driverProfile"
      :loading-view="driverView.loading"
      ref="driver" 
      @updated="driverUpdated"
    />
    <messenger 
      :toggle="messenger.toggle"
      :to="messenger.to"
      :user="user.username"
      :token="user.auth.token"
      @toggle-messenger="toggleMessenger"
      ref="messenger" 
    />
  </div>
</template>

<style type="text/css">

  .drivers {
    position: relative;
    background: var(--city-blue-50);
  }
  .drivers .toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .drivers .toolbar .filter {
    min-width: 160px;
  }

  .toolbar .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: transparent;
  }

  .drivers .filter .v-list-item__action {
    margin-right: 16px !important;
  }

  .driver-funnel {
    position: relative;
  }

  .step-selection {
    position: absolute !important;
    left: 8px;
    z-index: 2;
    background: var(--city-blue-50) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .snap-x {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 8px;
    scroll-margin: 8px;
  }
  .snap-child {
    scroll-snap-align: start;
  }

</style>

<script>
  // Icons
  import { mdiCog, mdiMagnify, mdiCircleMedium, mdiViewColumnOutline, mdiRefresh, mdiFilterVariant, mdiStarOutline, mdiStar, mdiCheckboxBlankOff } from '@mdi/js';

  // Utilities
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { mask } from 'vue-the-mask'
  import _ from 'lodash';
  import device from 'mobile-device-detect';
  var moment = require('moment');
  const md5 = require('md5');

  export default {
    name: 'Drivers',
    metaInfo: {
      title: 'Motoristas'
    },

    components: {
      Driver: () => import('@/views/drivers/Driver'),
      DriverList: () => import('@/components/DriverList'),
      Messenger: () => import('@/components/Messenger'),
    },

    data: () => ({
      icons: {
        mdiCog,
        mdiMagnify,
        mdiCircleMedium,
        mdiViewColumnOutline, 
        mdiRefresh,
        mdiFilterVariant,
        mdiStarOutline,
        mdiStar,
        mdiCheckboxBlankOff
      },
      item: null,
      items: {},
      driverView: {
        loading: false,
      },
      messenger: {
        toggle: false,
        to: [],
      },
      list: {
        'REG': {
          title: 'Pré-Cadastrados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'ACC': {
          title: 'Cadastrados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'FDB': {
          title: 'Avaliados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'SIG': {
          title: 'Aguardando Assinatura',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'ENR': {
          title: 'Aguardando Docs',
          items: [],
          order: [['events.updated', 'status.changedAt', 'rating', 'job.rating', 'fullname'], ['desc', 'desc', 'desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
        'FDD': {
          title: 'Docs Enviados',
          keys: ['FDD', 'FD+'],
          items: [],
          order: [['events.updated', 'status.changedAt', 'rating', 'job.rating', 'fullname'], ['desc', 'desc', 'desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
        'REA': {
          title: 'Preparados',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'SCH': {
          title: 'Agendamento',
          items: [],
          order: null,
          toggle: false,
          loading: false,
          updated: false,
        },
        'SC+': {
          title: 'Agendados',
          items: [],
          order: [['calendar.start', 'rating', 'job.rating', 'fullname'], ['asc', 'desc', 'desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
        'ACT': {
          title: 'Parceiros',
          items: [],
          order: [['rating', 'fullname'], ['desc', 'asc']],
          toggle: false,
          loading: false,
          updated: false,
        },
      },
      input: {
        lists: {
          toggle: false,
        },
        search: {
          toggle: false,
          focus: false,
          query: null,
          loading: false,
          disabled: false,
          messages: [],
          timer: null,
          cancel: null,
          results: {}
        },
        rating: {
          value: null,
          field: 'rating',
          loading: false,
          disabled: false,
          label: 'Avaliação',
          items: [
            {
              text: 5,
              value: 5,
              color: 'primary',
            },
            {
              text: 4,
              value: 4,
              color: 'secondary',
            },
            {
              text: 3,
              value: 3,
              color: 'lime',
            },
            {
              text: 2,
              value: 2,
              color: 'amber',
            },
            {
              text: 1,
              value: 1,
              color: 'red',
            },
          ]
        },
        tags: {
          value: null,
          field: 'tags',
          loading: false,
          disabled: false,
          label: 'Tags',
        },
        city: {
          value: null,
          field: 'address.city',
          loading: false,
          disabled: false,
          label: 'Cidade',
          items: [
            'Rio de Janeiro',
            'Belo Horizonte',
            'Petrópolis',
            'Florianópolis',
            'São José',
            'Palhoça',
            'Biguaçu',
            'São Paulo',
            'Guarulhos',
          ]
        },
        special_projects: {
          value: null,
          field: 'job.special_projects',
          loading: false,
          disabled: false,
          label: 'Projetos Especiais',
          items: [
            {
              text: 'Sim',
              value: 1
            },
            {
              text: 'Não',
              value: 0
            },
          ]
        },
        owner: {
          value: null,
          field: 'vehicle.isOwner',
          loading: false,
          disabled: false,
          label: 'Dono',
          items: [
            {
              text: 'Próprio',
              value: 1
            },
            {
              text: 'Emprestado',
              value: 0
            },
            {
              text: 'Locadora',
              value: 2
            },
          ]
        },
        gender: {
          value: null,
          field: 'gender',
          loading: false,
          disabled: false,
          label: 'Gênero',
          items: [
            {
              text: 'Masculino',
              value: 'M'
            },
            {
              text: 'Feminino',
              value: 'F'
            },
            {
              text: 'Outro',
              value: 'o'
            },
            {
              text: 'Prefiro não dizer',
              value: '-'
            },
          ]
        },
        model: {
          value: null,
          field: 'vehicle.model',
          loading: false,
          disabled: false,
          label: 'Modelo',
          items: [
            'Bolt', 
            'Celta', 
            'Classic',
            'Cobalt',
            'Corsa Classic',
            'Cruze',
            'Equinox',
            'Onix',
            'Onix +',
            'Prisma',
            'Spin',
            'Aircross',
            'C3',
            'C3 Picasso',
            'C4',
            'C4 Lounge',
            '500',
            'Argo',
            'Bravo',
            'Cronos',
            'Doblò',
            'Grand Siena',
            'Idea',
            'Linea',
            'Mobi',
            'Palio',
            'Palio Weekend',
            'Punto',
            'Siena',
            'Toro',
            'Uno',
            'Fusion',
            'Edge',
            'EcoSport',
            'Fiesta',
            'Focus',
            'Ka',
            'Ka +',
            'Accord',
            'City',
            'Civic',
            'CR-V',
            'Fit',
            'HRV',
            'WRV',
            'Azera',
            'Creta',
            'Elantra',
            'HB20',
            'HB20S',
            'HB20X',
            'ix20',
            'ix35',
            'Santa Fé',
            'Sonata',
            'Tucson',
            'Veloster',
            'Veracruz',
            'J3',
            'J5',
            'Bongo',
            'Cadenza',
            'Carens',
            'Carnival',
            'Cerato',
            'Optima',
            'Picanto',
            'Quoris',
            'Rio',
            'Sorento',
            'Soul',
            'Sportage',
            'Stinger',
            'Pajero',
            'Lancer',
            'Grand Livina',
            'Kicks',
            'Leaf',
            'Livina',
            'March',
            'Sentra',
            'Tiida',
            'Versa',
            '2008',
            '207',
            '207 Passion',
            '208',
            '3008',
            '408',
            '5008',
            'Captur',
            'Clio',
            'Duster',
            'Fluence',
            'Kwid',
            'Logan',
            'Sandero',
            'Corolla',
            'Etios',
            'Prius',
            'Yaris',
            'Space Fox',
            'Fox',
            'Gol',
            'Golf',
            'Jetta',
            'Polo',
            'T-Cross',
            'Tiguan',
            'Up',
            'Virtus',
            'Voyage',
            'Outro'
          ]
        },
        app_version: {
          value: null,
          field: 'app_version',
          loading: false,
          disabled: false,
          label: 'Versão App',
          items: [],
        },
        limit: {
          label: 'Qtd',
          value: 100,
          loading: false,
          disabled: false,
          items: [
            50,
            100,
            200,
            300
          ]
        },
      },
      device: {
        mobile: device.isMobileOnly,
        tablet: device.isTablet,
        landscape: false,
        width: null,
        height: null,
      },
    }),

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@drivers'),
      details: sync('app/views@details'),
      main: sync('app/views@main'),
      funnel: sync('drivers/funnel@toggle'),
      drivers: sync('drivers/data@items'),
      tags: sync('drivers/data@tags'),
      fleets: sync('drivers/data@fleets'),
      selectedList: sync('drivers/views@dashboard.selectedList'),
      bankList: sync('drivers/bankList'),
      selected: sync('drivers/data@selected'),
      prototype: sync('drivers/prototype'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      mobile () {
        return this.device.mobile && !this.device.tablet;
      },

      toggleDriver () {
        return this.selected!=null;
      },

      driverProfile () {
        return this.selected!=null ? this.items[this.selected] : null;
      },

      listHeight () {
        const mobileLandscape = this.device.landscape && this.device.mobile;
        return this.device.height - (mobileLandscape ? 0 : 64 + 16) - 64 - 48 - 16;
      }
    },

    watch: {
      $route (to, from) {
        this.updateView(to, from);
      },

      items: {
        immediate: true,
        deep: true,
        handler () {
          this.updateList();
        }
      },

      'input.search.query': function (query) {
        if (query && query.length >= 3) {
          // this.updateList();
        }
      },

      drivers: {
        immediate: true,
        deep: true,
        handler (drivers) {
          this.input.app_version.items = _.without(_.uniq(_.map(drivers, 'app_version'), 'undefined')).sort().reverse();
        }
      }
    },

    methods: {
      ...services,
      md5,

      updateList () {
        const items = _.values(this.items);
        _.each(_.omit(this.list, 'search'), (list, key) => {
            list.items = _.filter(items, item => {
              const status = item.status;
              return _.has(list, 'keys') ? _.size(_.intersection([status.value, status.super], list.keys))>0 : key==status.super;
            });
            // const empty = list.items.length==0;
            // }else if (empty) {
            //   list.toggle = false;
            // }
          // }
        });
      },

      toggleList (status, b) {
        this.list[status].toggle = b;
        if (b) this.loadList(status);
        this.funnel = _.keys(_.pickBy(this.list, l => l.toggle==true));
      },

      resetList () {
        _.each(this.list, l => {
          l.toggle = false;
        });
      },

      filterList (items) {
        let query = this.input.search.query;
        query = this.isValid(query) ? query.normalize("NFD").replace(/\p{Diacritic}/gu, "") : '';
        const list = this.searchEngine(items, query, true);
        // console.log(query, list);
        return !_.isEmpty(list) ? list : this.searchEngine(items, query);
      },

      searchEngine (items, query, exact) {
        exact = _.isNil(exact) ? false : exact;
        const key = exact ? new RegExp(`^${query}$`, 'gi') : new RegExp(query.replace(/\b +\b/g,'|'), 'gi');
        return _.filter(items, (item) => {
          return this.applyFilter(key, item) && (query != '' ? (key.test(item.fullname.normalize("NFD").replace(/\p{Diacritic}/gu, "")) || new RegExp(this.rawData(query), 'i').test(item.cpf) || key.test(item.cpf) || key.test(item.phone) || key.test(item.email) || key.test(item.vehicle.model) || key.test(item.vehicle.brand) || key.test(item.vehicle.plate)) : true);
        });
      },

      applyFilter (key, profile) {
        const conditions = _.map(['city', 'gender', 'owner', 'special_projects', 'model', 'rating', 'app_version', 'tags'], condition => {
          const filter = this.input[condition];
          return _.isNil(filter.value) || (_.isArray(filter.value)&&_.isEmpty(filter.value)) ? true : _.some(filter.value, v => {
            const value = _.get(profile, filter.field);
            return _.isArray(value) ? _.some(value, val => val==v) : v==value;
          });
        });
        return _.every(conditions);
      },

      // listTitle (key) {
      //   let title = '';
      //   title = this.list[key].title;
      //   return title;
      // },

      // filterByOwner (owner, list) {
      //   const result = _.filter(list, driver => {
      //     return owner==1 ? (driver.vehicle.isOwner==1&&driver.status.value=='DOC') || driver.status.value=='SIG' : driver.vehicle.isOwner==0&&driver.status.value=='DOC';
      //   });
      //   // console.log(owner, result);
      //   return result;
      // },

      searchTimer (toggle) {
        if (toggle) {
          if (this.input.search.timer!=null) clearTimeout(this.input.search.timer);
          this.input.search.timer = setTimeout(vue => {
            vue.searchDriver(this.input.search.query);
          }, 2000, this);
        }else{
          clearTimeout(this.input.search.timer);
          this.input.search.timer = null;
        }
      },

      updateView (route, previous) {
        // set content
        const driver = this.getParam(route, 'driver');

        if (driver) {
          this.getDriver(driver);
          console.log(this.$refs);
          if (_.has(this.$refs, 'driver')) this.$refs.driver.updateView();
        }else{
          const search = this.getQuery(route, 'search');
          if (!!search) {
            this.input.search.query = search;
            this.input.search.focus = true;
            this.searchDriver(search, this.input.search);
          }
          this.selected = null;
          if (!_.isNil(previous)) {
            const id = this.getParam(previous, 'driver');
            // const profile = this.drivers[id];
            // _.merge(this.items[id], profile);
            this.updateList();
          }
        }
      },

      driverUpdated (profile) {
        this.tags = _.union(this.tags, profile.tags);
        if (profile.status.value in this.list && !this.list[profile.status.value].toggle) {
          this.list[profile.status.value].toggle = true;
        }
        this.$set(this.items, this.selected, profile);
        this.updateList();
      },

      selectDriver (id) {
        this.cacheDrivers({ [id]: this.items[id] }, this.drivers);
        setTimeout(() => {
          this.$router.push({ path: `/drivers/${id}` });
        }, 250);
      },

      toggleMessenger (b, to) {
        console.log('messenger:', b, to);
        this.messenger.toggle = b;
        this.messenger.to = to;
      },

      parseFilters () {
        let filters = {}
        const city = this.input.city.value;
        if (!_.isNil(city)&&!_.isEmpty(city)) filters['cidade'] = city;
        const model = this.input.model.value;
        if (!_.isNil(model)&&!_.isEmpty(model)) filters['modelo'] = model;
        const owner = this.input.owner.value;
        if (!_.isNil(owner)&&!_.isEmpty(owner)) filters['dono'] = owner;
        const special_projects = this.input.special_projects.value;
        if (!_.isNil(special_projects)&&!_.isEmpty(special_projects)) filters['special_projects'] = special_projects;
        const gender = this.input.gender.value;
        if (!_.isNil(gender)&&!_.isEmpty(gender)) filters['genero'] = gender;
        const rating = this.input.rating.value;
        if (!_.isNil(rating)&&!_.isEmpty(rating)) filters['destaque'] = _.map(rating, (r) => r-3);
        const tags = this.input.tags.value;
        if (!_.isNil(tags)&&!_.isEmpty(tags)) filters['tags'] = tags;
        return filters;
      },

      loadList (status, update) {
        if (!_.isNil(update)&&update) _.each(this.list, l => {
          l.updated = false;
        });
        if (_.isNil(status)) {
          status = _.findKey(this.list, { 'updated': false, 'toggle': true });
          // status = _.findKey(this.list, { 'updated': false });
          if (_.isNil(status)) return false;
        }
        // console.log('get list:', status);
        const params = _.assign({}, { 
          'status': status, 
          'qtd': this.input.limit.value, 
          'lite': 1, 
          'calendar': status=='SC+'? 1 : 0 
        }, this.parseFilters());
        this.getDriverList(params, this.list[status], this.loadList);
      },

      getDriverList (params, loader, callback) {
        if (params.status=='ACT') _.unset(params, 'qtd');
        console.log('get list for ', params);
        this.input.search.messages = [];
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;

        const query = _.reduce(params, (s,v,k) => {
          return s + (_.isNil(v) ? '' : '&'+k+'='+v);
        },'');
        
        this.$api.COM({
          url: '/searchprofiledriverextadmin/'+username+'/'+token,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: params,
        })
        .then(response => {
          console.log('getDriverList => ',response);
          if(response.data.retorno==200){
            
            if (response.data.dados.length>0) {
              const items = this.processDrivers(response.data.dados, this.prototype);
              this.items = Object.assign({}, this.items, items);
              if (params.status=='ACT') this.cacheDrivers(items, this.drivers);
            }

          }else if(response.data.retorno==404){
            // no results
          }else if(response.data.retorno==401){
            this.getout();
            this.handleError(response.data.retorno, 'Sua sessão expirou...');
          }else{
            this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          if (_.has(params, 'status')) this.list[params.status].updated = true;
          loader.loading = false;
          if (!_.isNil(callback)) callback();
        });
      },
      searchDriver (query, loader, callback) {
        console.log('searching for ', query);
        this.input.search.messages = [];
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM({
          url: '/searchprofiledriveradmin/'+username+'/'+token+'/'+query,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            'lite': 1
          },
        })
        .then(response => {
          console.log('searchDriver => ',response);
          if(response.data.retorno==200){
            
            if (response.data.dados.length>0) {
              const items = this.processDrivers(response.data.dados, this.prototype);
              this.items = Object.assign({}, this.items, items);
            }else{
              this.toggleToast(
                true,
                'Nenhum resultado para a busca por '+query,
                5000,
                false,
              );
            }

          }else if(response.data.retorno==404){
            this.input.search.messages = ['Sem resultados'];
          }else if(response.data.retorno==401){
            this.getout();
            this.handleError(response.data.retorno, 'Sua sessão expirou...');
          }else{
            this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          loader.loading = false;
          if (!_.isNil(callback)) callback();
        });
      },
      
      getDriver (id) {
        console.log(true,'driver.getDriver');
        this.input.search.messages = [];
        this.driverView.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        this.selected = id;
        
        this.$api.COM
          .get('/getprofiledriveradmin/'+username+'/'+token+'/'+id+'/?format=json')
          .then(response => {
            console.log('getDriver => ',response);
            if(response.data.retorno==200){
              response.data.viewedAt = moment().format();
              // push driver to items list
              const items = this.processDrivers(response.data, this.prototype);
              this.items = Object.assign({}, this.items, items);
              // this.cacheDrivers(items, this.drivers);

              this.$nextTick(() => {
                // selected driver
                if (_.has(this.$route.params, 'driver')) {
                  if (_.has(this.$refs, 'driver')) this.$refs.driver.updateView();
                }
              })

            }else if(response.data.retorno==404){
              this.input.search.messages = ['Motorista não encontrado'];
            }else if(response.data.retorno==401){
              this.getout();
              this.handleError(response.data.retorno, 'Sua sessão expirou...');
            }else{
              this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.driverView.loading = false;
            console.log(false,'driver.getDriver');
          });
      },

      getTags () {
        console.log('driver.getTags');
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/driver/tags/'+username+'/'+token+'/?format=json')
          .then(response => {
            console.log('getTags => ', response);
            if(response.data.length>0){
              this.tags = response.data;
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      getFleets () {
        console.log('driver.getFleets...');
        const username = this.user.username;
        const token = this.user.auth.token;

        const params = {
          username,
          token,
        };

        this.$api.COM
          .get('/fleet/', { params })
          .then(response => {
            console.log('getFleets => ', response);
            this.fleets = response.data;
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      getBankList () {
        console.log('driver.getBanklist');
        const username = this.user.username;
        const token = this.user.auth.token;

        this.$api.COM
          .get('/getbancosadmin/'+username+'/'+token+'/?format=json')
          .then(response => {
            console.log('getBankList => ', response);
            if(response.data.length>0){
              this.bankList = response.data.map(function(item){
                return { id: item.id_banco, code: item.cod_banco, title: item.nm_banco };
              });
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
          });
      },

      onResize () {
        this.device.width = window.innerWidth;
        this.device.height = window.innerHeight;
        this.device.landscape = this.device.width > this.device.height;
        console.log('display update', this.device.mobile, this.device.landscape);
      },
    },

    created () {
      this.getBankList();
      this.getTags();
      this.getFleets();
    },

    mounted () {
      this.items = _.clone(this.drivers);
      _.each(this.list, (list, key) => {
        this.$set(this.list[key], 'toggle', _.indexOf(this.funnel, key) >= 0);
      });
      // _.each(this.funnel, key => {
      //   this.$set(this.list[key], 'toggle', true);
      // });
      this.updateView(this.$route);
      this.loadList();
      this.onResize();
    },

    directives: {
      mask,
    },

  }

</script>
